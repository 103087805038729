import React from 'react';
import RecipeItem from './item';


function RecipeContentItem(props) {
  const { sectionTitle, sectionImage } = props.section;
  const sectionTitleImage = `/images/${process.env.REACT_APP_BRAND_NAME}/recipes/${sectionImage}`;
  
  function RecipeContentHeader(){
    if(sectionImage){
      return <img src={sectionTitleImage} alt={sectionTitle}/>
    } else if (!sectionImage && sectionTitle) {
      return <h3 className='sectionTitle'>{sectionTitle}</h3>
    } else {
      return ''
    }
  }

  return (
    <div className="recipe-section" id={sectionTitle}>
      <RecipeContentHeader/>
      <div className='recipeBlock'>
        {props.section.sectionItems.map((recipe, i) => {
          return <RecipeItem recipe={recipe} key={i}></RecipeItem>;
        })}
      </div>
      <div className='clearer'></div>
    </div>
  );
}
export default RecipeContentItem;
